import challenge from './challenge';
import course from './course';
import guide from './guide';
import tutorial from './tutorial';
export var Templates;
(function (Templates) {
    Templates["GUIDE"] = "guide";
    Templates["CHALLENGE"] = "challenge";
    Templates["COURSE"] = "mini-course";
    Templates["TUTORIAL"] = "tutorial";
})(Templates || (Templates = {}));
export default {
    guide,
    challenge,
    tutorial,
    'mini-course': course,
};
