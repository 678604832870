import { useParams, useSearchParams } from '@remix-run/react';
import { createContext, useContext, useState, useEffect, useRef } from 'react';
import { displayToaster } from 'aslan';
import { TheLeapAnalytics } from 'analytics';
import { useT } from 'i18n';
import { StoryCardTypeEnum, StoryCardImageLayoutEnum, createStoryCard, DEFAULT_STYLES, } from 'leap-stories';
import { Templates } from '../models';
const AILeapStoryContext = createContext({
    title: '',
    salesPage: null,
    outlineProgressState: 'inprogress',
    cards: [],
    apiError: null,
    setCards: (_cards) => { },
});
function handleEvent(callback) {
    return (event) => {
        try {
            return callback(event);
        }
        catch (error) {
            console.warn(event);
            console.error(error);
        }
    };
}
export const useAILeapStoryContext = () => useContext(AILeapStoryContext);
export const AILeapStoryProvider = ({ streamEndpoint, children, defaultCards, }) => {
    const t = useT('translation', 'generator');
    const params = useParams();
    const [title, setTitle] = useState('');
    const [baseStyles, setBaseStyles] = useState(DEFAULT_STYLES);
    const [salesPage, setSalesPageData] = useState(null);
    const ref = useRef(true);
    const [cards, setCards] = useState(defaultCards ?? []);
    const [imageGallery, setImageGallery] = useState();
    const [newCard, setNewCard] = useState(null);
    const [outlineProgressState, setOutlineProgressState] = useState('inprogress');
    const [apiError, setApiError] = useState(null);
    const [searchParams] = useSearchParams();
    useEffect(() => {
        if (!ref.current) {
            return;
        }
        ref.current = false;
        let eventSource;
        let handler;
        eventSource = new EventSource(`${streamEndpoint}${searchParams.get('debug') ? `?debug=${searchParams.get('debug')}` : ''}`);
        eventSource.addEventListener('message', (event) => {
            if (event.data === '[DONE]') {
                eventSource.close();
                setOutlineProgressState('done');
            }
        });
        eventSource.addEventListener('apiError', (event) => {
            eventSource.close();
            setOutlineProgressState('failed');
            setApiError(event.data);
            displayToaster({
                type: 'alert',
                message: event.data === 'rate_limit'
                    ? t('outline.apiError.rateLimit')
                    : t('outline.apiError.unknown'),
                options: { duration: Infinity },
            });
        });
        eventSource.addEventListener('finishReason', (event) => {
            if (event.data !== 'length') {
                return;
            }
            eventSource.close();
            setOutlineProgressState('failed');
            setApiError('token_length');
            displayToaster({
                type: 'alert',
                message: t('outline.apiError.tokenLength'),
                options: { duration: Infinity },
            });
        });
        eventSource.addEventListener('tokenUsage', (event) => {
            const { promptUsed, promptTokens, totalTokens, completionTokens } = JSON.parse(event.data);
            TheLeapAnalytics.track('OpenAI Token Usage', {
                'Prompt Used': promptUsed,
                'Total Tokens': totalTokens,
                'Prompt Tokens': promptTokens,
                'Completion Tokens': completionTokens,
            });
        });
        eventSource.addEventListener('metadata', handleEvent((event) => {
            const metadata = JSON.parse(event.data);
            setTitle(metadata.title);
            setBaseStyles({
                background: { color: metadata.backgroundColor },
                title: { color: metadata.titleColor },
                text: { color: metadata.textColor },
            });
        }));
        eventSource.addEventListener('salesPage', handleEvent((event) => {
            setSalesPageData(JSON.parse(event.data));
        }));
        eventSource.addEventListener('images', handleEvent((event) => {
            const data = JSON.parse(event.data);
            setImageGallery(data);
        }));
        eventSource.addEventListener('card', handleEvent((event) => {
            const cardData = JSON.parse(event.data.trim());
            if (cardData.variant) {
                let newCard = createStoryCard(cardData);
                return setNewCard(newCard);
            }
        }));
        return () => {
            if (eventSource && handler) {
                eventSource.close();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!newCard) {
            return;
        }
        const isFirstCard = cards.length === 0;
        const isSectionTitleCard = newCard.variant === StoryCardTypeEnum.RICH_TEXT &&
            (newCard.text?.match(/week|section|step/i)?.length ||
                (!newCard.text?.match(/takeaway/i)?.length &&
                    params.templateType === Templates.COURSE));
        const isLinkCard = newCard.variant === StoryCardTypeEnum.LINK;
        const isLastCard = newCard.title?.match(/thank you|thanks|congratulations/i)?.length;
        if (imageGallery) {
            newCard.image = { isNew: true };
            if (isFirstCard) {
                // Use FULL layout on intro card
                newCard.image.unsplashPhoto = imageGallery.portrait.shift();
                newCard.imageLayout = StoryCardImageLayoutEnum.TOP;
            }
            else if (isSectionTitleCard) {
                // Use TOP layout for chapter slides
                newCard.image.unsplashPhoto = imageGallery.landscape.shift();
                newCard.imageLayout = StoryCardImageLayoutEnum.TOP;
            }
            else if (isLinkCard) {
                // Use FRAME layout for link slides
                newCard.image.unsplashPhoto = imageGallery.portrait.shift();
                newCard.imageLayout = StoryCardImageLayoutEnum.FRAME;
            }
            else {
                newCard.image = undefined;
            }
        }
        if (isFirstCard || isSectionTitleCard || isLastCard) {
            newCard.styles = baseStyles;
        }
        else {
            newCard.styles = DEFAULT_STYLES;
        }
        setCards([...cards, newCard]);
        setNewCard(null);
        setImageGallery(imageGallery);
    }, [newCard, cards, imageGallery, params.templateType, baseStyles]);
    useEffect(() => {
        if (imageGallery && (!salesPage?.bannerImage || !salesPage?.tileImage)) {
            const photo = imageGallery.landscape.shift();
            setSalesPageData({
                richDescription: salesPage?.richDescription || '',
                ...salesPage,
                bannerImage: {
                    unsplashPhoto: photo,
                },
                tileImage: {
                    unsplashPhoto: photo,
                },
            });
        }
    }, [imageGallery, salesPage]);
    useEffect(() => {
        if (outlineProgressState === 'done') {
            TheLeapAnalytics.track('AI Generation: Outline Completed', {
                'Card Titles': cards?.map((card) => card.title || 'empty title') || [],
                'Card Types': cards?.map((card) => card.variant || 'RICH_TEXT') || [],
                ...Object.values(StoryCardTypeEnum).reduce((acc, type) => ({
                    ...acc,
                    [`num${type}`]: cards?.filter((card) => card.variant === type)
                        .length,
                }), {}),
            });
        }
        else if (outlineProgressState === 'failed') {
            TheLeapAnalytics.track('AI Generation: API Failed', {});
        }
    }, [cards, outlineProgressState, baseStyles]);
    return (<AILeapStoryContext.Provider value={{
            title,
            salesPage,
            cards,
            apiError,
            outlineProgressState,
            setCards,
        }}>
      {children}
    </AILeapStoryContext.Provider>);
};
export default AILeapStoryProvider;
